import type { InMemoryCacheConfig } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'

import { mergeArrayField } from '../utilities/merge-array-field'

const IS_CI = process.env.CI || process.env.NEXT_PUBLIC_CI

export const DEV_GRAPHQL_URL =
  process.env.DEV_GRAPHQL_URL || !IS_CI
    ? 'https://local.jusbrasil.com.br/api/_internal/graphql'
    : 'http://localhost:4200/api/_internal/graphql'
export const GRAPHQL_URL = process.env.GRAPHQL_URL || DEV_GRAPHQL_URL
export const NEXT_PUBLIC_GRAPHQL_URL = process.env.NEXT_PUBLIC_GRAPHQL_URL || DEV_GRAPHQL_URL

export const inMemoryCacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    RootApi: {
      merge: true,
      fields: {
        me: {
          merge: true,
        },
        searchLawsuitPartyEntities: relayStylePagination(),
      },
    },
    CRMLawsuit: {
      merge: true,
      fields: {
        attachments: relayStylePagination(() => ['source', 'kindGroup', 'kind']),
        timeline: relayStylePagination(() => ['source', 'eventTypes']),
      },
    },
    CRMLawsuitPartyEntity: {
      merge: true,
      fields: {
        lawsuits: relayStylePagination(),
        lawsuitsWithoutEntity: relayStylePagination(() => ['@connection', ['key']]),
      },
    },
    Topic: {
      merge: true,
    },
    Contact: {
      merge: true,
      fields: {
        lawsuits: relayStylePagination(() => ['@connection', ['key']]),
        lawsuitPartyEntities: relayStylePagination(),
        relatedPersonsWithMostOccurrences: relayStylePagination(() => ['first']),
      },
    },
    CRMTimelineItem: {
      merge: true,
      fields: {
        lawsuitAttachments: {
          keyArgs: false,
          merge: mergeArrayField, // FIXME: Find a way to merge without having to manually set keyArgs/merge fn. @guifrrs
        },
      },
    },
    Document: {
      merge: true,
      fields: {
        comments: relayStylePagination(),
      },
    },
    Profile: {
      merge: true,
      fields: {
        boxProfileInfo: {
          merge: true,
        },
        featureTokens: {
          merge: true,
        },
      },
    },
    Organization: {
      merge: true,
      fields: {
        monitoringLawsuits: relayStylePagination(() => ['@connection', ['key']]),
      },
    },
    User: {
      merge: true,
    },
    AnticipationLoanPartner: {
      merge: true,
      fields: {
        reports: relayStylePagination(),
      },
    },
  },
}
